import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import parse from 'html-react-parser'
import { Logo } from '../components/icons'
import FadeInOut from "../utils/FadeInOut";

import Vimeo from '@u-wave/react-vimeo';

const useSessionModal = () => {
  const session = "landing";
  const [show, setShow] = useState(true);

  //const toggleShow = () => setShow(!show);

  const toggleShow = () => {
    const modalKey = "modalSession";
    sessionStorage.setItem(modalKey, session);
    setShow(!show);
  };

  useEffect(() => {
    const modalKey = "modalSession";
    const modalSession = sessionStorage.getItem(modalKey);
    setShow(modalSession !== session);

    // if (show) {
    //   const timeout = setTimeout(() => toggleShow(), 3000);
    //   return () => {
    //     clearTimeout(timeout);
    //   };
    // }

  });

  return [show, toggleShow];
};

const Landing = ({data}) => {

  const [videoIndex, setVideoIndex] = useState(0);
  const [volume, setVolume] = useState(0);
  const [paused, setPaused] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isVolumeActive, setIsVolumeActive] = useState(false);

  const handlePause = (event) => {
    setPaused(event.target.checked);
    setIsActive(!isActive);
  };

  const handlePlayerPause = () => {
    setPaused(true);
  };

  const handlePlayerPlay = () => {
    setPaused(false);
  };

  const handleVolume = () => {
    setVolume((prevVolume) => (prevVolume === 1 ? 0 : 1));
    setIsVolumeActive(!isVolumeActive);
  };

  const selectVideo = (index) => {
    setVideoIndex(index);
  };

  const vimeoEmbed = data.wp.siteOptions.acf.videoEmbed;

  const videos = [{ id: vimeoEmbed, name: 'Studio Howes' }];
  const video = videos[videoIndex];



  const aspectRatio = 16 / 9; // Assuming 16:9 aspect ratio, modify if necessary
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  const resizeVideo = () => {
    // Get the viewport width and height
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the desired video width and height based on the aspect ratio
    let newVideoWidth, newVideoHeight;

    if (viewportWidth / viewportHeight > aspectRatio) {
      // If viewport is wider than the aspect ratio
      newVideoWidth = viewportWidth;
      newVideoHeight = viewportWidth / aspectRatio;
    } else {
      // If viewport is taller than the aspect ratio
      newVideoWidth = viewportHeight * aspectRatio;
      newVideoHeight = viewportHeight;
    }

    // Apply the new video width and height
    setVideoWidth(newVideoWidth);
    setVideoHeight(newVideoHeight);
  };

  useEffect(() => {
    // Initial resizing
    resizeVideo();

    // Add resize event listener to handle resizing
    window.addEventListener('resize', resizeVideo);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', resizeVideo);
    };
  }, []);
  


  const [show, toggleShow] = useSessionModal();
  const logoColor = data.wp.siteOptions.acf.logoColour;
  const type = data.wp.siteOptions.acf.type;

  // Add a new state variable to control the fade-out effect
  const [fadeOut, setFadeOut] = useState(false);

  // Function to trigger the fade-out effect
  const handleFadeOut = () => {
    setFadeOut(true);
    setTimeout(() => toggleShow(), 500); // Adjust the duration to match your CSS transition duration
  };
  
  // New useEffect for triggering handleFadeOut after 3 seconds
    useEffect(() => {
      if (show) {
      const timeout = setTimeout(() => {
        handleFadeOut();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
    }, []);


  return (
    show && ( // Only render if 'show' is true
    <section id="landing" className={fadeOut ? 'fade-out' : ''}>
      <div onClick={handleFadeOut} className="landing__inner">
        {type === 'video' ? (
          <div className="landing-video-autoplay">
            <div className="landing-video-container">
              <Vimeo
                video={video.id}
                width={videoWidth}
                height={videoHeight}
                autoplay
                muted
                loop
                controls={false}
                volume={volume}
                paused={paused}
                onPause={handlePlayerPause}
                onPlay={handlePlayerPlay}
              />
            </div>
          </div>
        ) : (
          data.wp.siteOptions.acf.landingImage && (
            <GatsbyImage
              image={getImage(data.wp.siteOptions.acf.landingImage?.localFile)}
              alt="Studio Howes"
            />
          )
        )}

        <div className="landing__logo">
          <div className="desktop">
            {logoColor === '#ffffff' ? <Logo color="#ffffff" /> : <Logo color="#000000" />}
          </div>

          <div className="mobile">
            {logoColor === '#ffffff' ? <Logo color="#ffffff" /> : <Logo color="#000000" />}
          </div>
        </div>

        <div className="landing__cta">
            {data.wp.siteOptions.acf.landingLargeText && 
              <h1 style={{ color: data.wp.siteOptions.acf.logoColor }}>{parse(data.wp.siteOptions.acf.landingLargeText)}</h1>
            }

            {data.wp.siteOptions.acf.landingButtonText && 
              <a style={{ color: data.wp.siteOptions.acf.logoColor }} href={data.wp.siteOptions.acf.landingButtonUrl}>{parse(data.wp.siteOptions.acf.landingButtonText)}</a>
            }
        </div>
      </div>
    </section>
    )
  );
};

export default Landing;