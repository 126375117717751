import React, { useEffect } from 'react';
import Link from '../utils/link';
import NewsletterForm from '../components/newsletter-form';
import { LogoMark, Close } from '../components/icons';

function Footer({ location, isModalOpen, openModal, closeModal }) {
  // Extract the pathname from the location prop
  const currentPath = location.pathname;
  const isPortfolioPage = currentPath.startsWith('/portfolio');

  useEffect(() => {
    if (!isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);



  return (
    <>
      {/* {isPortfolioPage && <NewsletterForm />} */}



      <div className={`popup popup__newsletter ${isModalOpen ? 'active' : 'notactive'}`}>
        <div className="popup__wrapper">
          <h3>Join our Newsletter</h3>
          <div className='popup__header'>
          </div>
          <div className='popup__form'>
            <NewsletterForm />
          </div>


          <button
              type='button'
              className='popup__close'
              onClick={closeModal}
            >
              <Close color={'#EDE5DE'}/>
            </button>

            {/* <button
              className='btn btn--cancel'
              type='button'
              onClick={closeModal}
            >
              
              <svg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <line x1='0.353553' y1='0.649376' x2='20.3536' y2='20.6494' stroke='black' />
                <line x1='20.3536' y1='1.35648' x2='0.353554' y2='21.3565' stroke='black' />
              </svg>
            </button> */}
          </div>
      </div>


      <footer className="footer">
        <div className="footer__top">
          <div className="footer__row">
            <div className="col">
              <Link to="https://taylorhowes.co.uk">Taylor Howes</Link>
            </div>

            <div className="col">
              <Link to="https://howesandlandino.co.uk">Howes & Landino</Link>
            </div>
          </div>
        </div>

        <div className="footer__inner">
          <div className="footer__col">
            {/* <Link to="/" title="Studio Howes">
              <span className="logo-mark">
                <LogoMark color={'#1D1D1B'} />
              </span>
            </Link> */}

            <nav className="footer__nav">
              <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/portfolio/residential">Residential</Link></li>
                <li><Link to="/portfolio/developer">Developer</Link></li>
                {/* <li><Link to="/portfolio/hospitality">Hospitality</Link></li> */}
                <li><Link to="/careers">Careers</Link></li>
                {/* <li><Link to="/contact/">Newsletter</Link></li> */}
                <li><button className='btn' onClick={openModal}>Newsletter</button></li>
                <li><Link to="https://www.instagram.com/studiohowes">Instagram</Link></li>
                <li><Link to="https://www.pinterest.com.au/studiohowes">Pinterest</Link></li>
                <li><Link to="/legal">Legal</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li>
                  <Link to="/" title="Studio Howes" className="footer__logo">
                    Studio Howes &copy; {new Date().getFullYear()}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
